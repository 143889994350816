<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="7">
            <div class="input-group">
              <input
                  id="example-search-input"
                  class="form-control py-2 border-right-0 border"
                  type="search"
                  v-model="filters.search"
                  :placeholder="$t('Search')"
              >
              <span class="input-group-append">
                <b-button variant="primary" @click="fetchList">
                  <b-icon icon="search" />
                </b-button>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="w-100 mt-2">
      <b-overlay
          id="overlay-background"
          :show="showTableOverlay"
          rounded="sm"
      >
        <b-card>
          <b-table
              striped
              hover
              :items="datas"
              :fields="fields"
              responsive=""
          >
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{ (data.index +1) + (perPage)*(currentPage-1) }}</span>
            </template>
            <template #cell(register_date)="{item}">
              <span class="d-flex justify-content-center mt-1">
                <span>
                  {{ item.register_date | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
            </template>
            <template #cell(cur_status)="{item}">
              <span :class="`text-${item.cur_status.color}`">
                {{item.cur_status.name}}
              </span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    @click="handlePush(data.item.id)"
                >
                  <b-icon
                      icon="eye"
                  />
                </b-button>
                <a v-if="data.item.file_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file_path}`">
                  <b-button size="sm" variant="info">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
                <a v-if="data.item.file2_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file2_path}`" class="ml-1">
                  <b-button size="sm" variant="warning">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
                <a v-if="data.item.file3_path" target="_blank" :href="`${backendPath.backendPath}${data.item.file3_path}`" class="ml-1">
                  <b-button size="sm" variant="success">
                    <b-icon icon="download"></b-icon>
                  </b-button>
                </a>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-overlay>

    </div>
    <div class="overflow-auto text-center">
      <b-pagination
          v-model="pagination.page"
          align="center"
          :total-rows="total"
          :per-page="pagination.pageSize"
          first-number
          size="lg"
          class="pt-2"
          @input="fetchList"
      />
    </div>
  </div>
</template>

<script>
import { getDocs } from '@/api/documents'
import { listTemplate } from '@/utils/mixins/listTemplate'
import backendPath from '../libs/vue-axios.config'

const actions = {
  get: getDocs,
}

export default {
  name: 'DocumentByCategoryAndYear',
  mixins: [
      listTemplate,
  ],
  data() {
    return {
      actions,
      backendPath,
      rows: 12,
      perPage: 1,
      currentPage: 5,
      documents: [],
      showTableOverlay: false,
      filters: {
        ...this.$route.params,
        search: '',
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'name',
          label: this.$t('Name'),
        },
        {
          key: 'number',
          label: this.$t('Number'),
        },
        {
          key: 'year.year',
          label: this.$t('Year'),
        },
        {
          key: 'register_date',
          label: this.$t('Register_Date'),
        },
        {
          key: 'cur_status',
          label: this.$t('Current_status'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  methods: {
    handleEdit(id) {
      this.$router.push({ name: 'completed/1', params: { id } })
    },
    fetchDocuments() {
      getDocs(this.$route.params).then(res => {
        this.documents = res.data.data
      })
    },
    handlePush(id) {
      this.$router.push({ name: 'DocumentParagraphByYear', params: { id } })
    },
  },
}
</script>

<style scoped>

</style>
